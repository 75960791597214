html, body {
  min-width: 100vw;
  min-height: 100vh;
  color: #ffffffe6;
  margin: 0;
  padding: 0;
  font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  font-size: 10px;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
  line-height: 1.33;
}

body {
  justify-content: center;
  align-items: stretch;
  display: flex;
}

h1, h2, h3 {
  color: inherit;
  margin: 0;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 1.66rem;
}

a {
  color: inherit;
}

@media (min-width: 576px) and (min-height: 420px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 992px) and (min-height: 640px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 1200px) and (min-height: 768px) {
  html {
    font-size: 18px;
  }
}

main {
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template: "main" 1fr
                 "projects" min-content
                 "footer" min-content
                 / 1fr;
  justify-content: center;
  align-items: center;
  display: grid;
}

.glass {
  z-index: 2;
  -webkit-backdrop-filter: blur(6.5px);
  backdrop-filter: blur(6.5px);
  background: #76bbf180;
  border: .1rem solid #ffffff2e;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 8px 32px #1f26875e;
}

.card {
  max-width: calc(100vw - 2rem);
  text-align: center;
  flex-direction: column;
  grid-area: main;
  justify-content: center;
  place-self: center;
  margin-top: 1rem;
  padding: 2rem 4rem;
  display: flex;
}

.card h1, .card h2 {
  margin-bottom: 1rem;
}

.card .links {
  width: 18rem;
  background-color: #ffffff54;
  border: .1rem solid #ffffff54;
  border-radius: .5rem;
  flex-wrap: wrap;
  grid-area: links;
  justify-content: space-between;
  align-self: center;
  column-gap: .5rem;
  margin-top: 2rem;
  display: flex;
  box-shadow: 0 8px 32px #ffffff80;
}

@media (min-width: 576px) {
  .card .links {
    width: auto;
  }
}

.card .links .button {
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  background-color: #fff0;
  border: none;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  transition: background-color .25s ease-in-out, box-shadow .25s ease-in-out;
  display: flex;
  box-shadow: 0 0 32px #fff0;
}

.card .links .button:hover {
  background-color: #ffffff80;
  box-shadow: 0 0 32px #fffc;
}

.card .links .button svg {
  width: 100%;
  max-width: 2rem;
  max-height: 2rem;
}

.card .links .button span.hidden {
  display: none;
}

.projects {
  grid-area: projects;
  place-self: center;
}

.projects .button {
  cursor: pointer;
  color: inherit;
  border-radius: .5rem;
  padding: 1rem 2rem;
  font-size: 1rem;
  transition: background-color .25s ease-in-out;
}

.projects .button:hover {
  background-color: #ffffff80;
  box-shadow: 0 8px 32px #1f268780;
}

footer {
  grid-area: footer;
  justify-self: center;
  padding: 1rem;
  font-size: 1rem;
}

.background, .foreground {
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  position: fixed;
  inset: 0;
}

.background {
  z-index: -2;
}

.foreground {
  z-index: -1;
  display: flex;
}

.foreground div {
  width: 4rem;
  height: 4rem;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  transform-origin: center;
  color: initial;
  background: #ffffff40;
  border: 1px solid #ffffff2e;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  font-size: 2rem;
  display: flex;
  position: absolute;
}

.foreground circle {
  stroke: #ffffff4d;
  stroke-width: 2px;
}

/*# sourceMappingURL=index.e959c6ae.css.map */
