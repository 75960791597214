html,
body {
  margin: 0;
  padding: 0;
  min-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.9);
}

* {
  box-sizing: border-box;
  line-height: 1.33;
}

body {
  display: flex;
  justify-content: center;
  align-items: stretch;
}

h1,
h2,
h3 {
  margin: 0;
  color: inherit;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 1.66rem;
}

a {
  color: inherit;
}

@media (min-width: 576px) and (min-height: 420px) {
  html {
    font-size: 12px;
  }
}
@media (min-width: 992px) and (min-height: 640px) {
  html {
    font-size: 14px;
  }
}
@media (min-width: 1200px) and (min-height: 768px) {
  html {
    font-size: 18px;
  }
}
main {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr min-content min-content;
  grid-template-areas: "main" "projects" "footer";
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  align-items: center;
  justify-content: center;
}

.glass {
  z-index: 2;
  overflow: hidden;
  background: rgba(118, 187, 241, 0.5);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border: 0.1rem solid rgba(255, 255, 255, 0.18);
  border-radius: 1rem;
  backdrop-filter: blur(6.5px);
}

.card {
  grid-area: main;
  justify-self: center;
  align-self: center;
  max-width: calc(100vw - 2rem);
  margin-top: 1rem;
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.card h1,
.card h2 {
  margin-bottom: 1rem;
}
.card .links {
  grid-area: links;
  width: 18rem;
  align-self: center;
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 0.5rem;
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0.33);
  border: 0.1rem solid rgba(255, 255, 255, 0.33);
  box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.5);
}
@media (min-width: 576px) {
  .card .links {
    width: auto;
  }
}
.card .links .button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0.5rem;
  width: 4rem;
  height: 4rem;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;
  box-shadow: 0 0 32px 0 rgba(255, 255, 255, 0);
  transition: background-color 250ms ease-in-out, box-shadow 250ms ease-in-out;
}
.card .links .button:hover {
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 32px 0 rgba(255, 255, 255, 0.8);
}
.card .links .button svg {
  width: 100%;
  max-width: 2rem;
  max-height: 2rem;
}
.card .links .button span.hidden {
    display: none;
}

.projects {
  grid-area: projects;
  align-self: center;
  justify-self: center;
}
.projects .button {
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  font-size: 1rem;
  cursor: pointer;
  color: inherit;
  transition: background-color 250ms ease-in-out;
}
.projects .button:hover {
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.5);
}

footer {
  grid-area: footer;
  font-size: 1rem;
  justify-self: center;
  padding: 1rem;
}

.background,
.foreground {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.background {
  z-index: -2;
}

.foreground {
  z-index: -1;
  display: flex;
}
.foreground div {
  width: 4rem;
  height: 4rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  background: rgba(255, 255, 255, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 0.5rem;
  backdrop-filter: blur(4px);
  position: absolute;
  transform-origin: center;
  color: initial;
}
.foreground circle {
  stroke: rgba(255, 255, 255, 0.3);
  stroke-width: 2;
}
